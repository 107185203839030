export function isNull<T>(value: T): boolean {
    return value === null;
}

export function isUndefined<T>(value: T): boolean {
    return value === undefined;
}

export function isNumber(value: unknown): boolean {
    return typeof value === 'number';
}

export function empty<T>(value: T): boolean {
    if (Array.isArray(value)) {
        return !value.length;
    }
    return isNull(value) || isUndefined(value);
}

export function omitEmpty<T extends Record<string, unknown>>(options: T): Partial<T> {
    return Object.fromEntries(Object.entries(options)
        .filter(([, v]) => !empty(v))) as Partial<T>;
}
